
.annonces {

	&-count {
		font-size: rem(14px);
		color: $colorDiscrete;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	&-list {
		margin-top: -15px;
		margin-bottom: -15px;
	}

	&-item {
		padding-top: 15px;
		padding-bottom: 15px;
		position: relative;
	}

	&-link {
		// @include frame(true);
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
		width: 100%;

		&:hover, &:active, &:focus {
			text-decoration: none;
		}
	}

    .annonce-img{position:relative}
    
    .border-img{    
    border: 1px solid #fff;
    height: 94%;
    width: 94%;
    top: 3%;
    left: 3%;
    position: absolute;}
    
	& &-img {
		height: rem(260px);
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	& &-imgNone {
		object-fit: contain;
		height: rem(260px);
		width: 100%;
		background-color: #fff;
	}

	&-text {
		padding: rem(15px) rem(20px) rem(20px);
		font-size: rem(16px);
		line-height: 1.2;
	}

	& &-title {
		all: unset;
		font-weight: 700;
		color: #474747;
        font-size: 1.3rem;
        min-height: 50px;
        text-align: center;
		text-transform: uppercase;

		&::after {
			content: none;
		}
	}


	&-price {
		font-weight: 700;
		font-size: rem(20px);
		padding-top: rem(10px);
		display: block;
        margin: 5px 0;
        padding-bottom: 5px;
        text-align: center;
        color:#fff;
        background: #474747;
        text-shadow: 0 0 5px #000;
     
	}

	&-favorites {
		all: unset;
		position: absolute;
		top: rem(35px);
		right: rem(32px);
		z-index: 1;
		line-height: 1;
		height: rem(21px);
		width: rem(21px);

		&::before, &::after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&::before {
			z-index: -1;
			border-radius: 100%;
			width: calc(100% + #{rem(10px)});
			height: calc(100% + #{rem(10px)});
			background-color: #fff;
		}

		&::after {
			content: "";
			height: 100%;
			width: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
			transition: background-image .2s;

			// @include favoriteIcon(#868686, white);
		}

		&--active::after, &:hover::after {
			// @include favoriteIcon($primary, $primary);
		}
	}

	&-mapTab {
		margin-top: rem(112px);
		margin-bottom: rem(50px);
		height: rem(600px);
	}

	&-map {
		height: 100%;
		// @include frame;

		.leaflet-popup-content-wrapper {
			border-radius: 0;
			box-shadow: none;
			background-color: transparent;
		}

		.leaflet-popup-content {
			margin: 0;
		}
	}

	&-mapLink {
		min-width: rem(250px);
		background-color: #fff;
	}

	&-mapImg {
		height: rem(160px);
	}
    
    
    .detail-mea_immo{
        padding: 5px 10px;
        color:$secondary;
        
        div{
            margin:10px 0;
            span{
                font-size: 1.5rem;
                padding-right: 5px;
                vertical-align: middle;
            }
        }
    }
   
    
}
.annonces.mea{
        padding: 30px 10px;
        transition: all 12s linear;
    
    
        &:before{  
        content:url(/img/chev-r-left.png) ;
        position: absolute;
        top: 0;
        left: 0;
                    transition: all 0.2s linear;
        } 
        
        &:after{ 
        content:url(/img/chev-r-right.png) ;
        position: absolute;
        bottom: 0;
        right: 0;
                    transition: all 0.2s linear;
        } 
    }

.annonces:hover:before{top: 5px;left: 5px}
.annonces:hover:after{before: 5px;right: 5px}

.leaflet-popup-tip-container {
	bottom: 0;
}




