@import '../abstracts/_variables';
@import '../abstracts/_mixins';
@import '../modules/galerie/_galerie-album';

.galerie-widget {
    text-align: center;
    h2{text-align: center; font-size: 2rem;   margin: 0 auto 30px;}
    #galerie-slider{
        padding-bottom: 50px;
        a{position: relative;background: #474747}

        p.galerie-title{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;
            margin: auto;
            font-size: 1.5rem;
            text-transform: uppercase;
            color: #fff;
            display: none;
        }

         .border-img{    
            border: 1px solid #fff;
            height: 94%;
            width: 94%;
            top: 3%;
            left: 3%;
            position: absolute;
        }

        a:hover img{opacity: 0.2}
        a:hover p.galerie-title{display: block}
    }
}