@import '../modules/webimmo/annonces';
#mea_immo{
    h2{text-align: center; font-size: 2rem;   margin: 0 auto 30px;}

    .widgetWebimmo {
        $parent: &;
        padding-top: rem(40px);
        padding-bottom: rem(40px);

        // @include title;

        &-swiper {
            @media (min-width: 576px) {
                padding-left: 5rem;
                padding-right: 5rem;
            }
        }

        &-wrapper {
            padding-bottom: rem(120px);
        }

        &-slide {
            text-align: left;
            height: auto;
            opacity: 0;
            transition: opacity .3s ease-out;

            &--visible {
                opacity: 1;
            }
        }

        &-favorites {
            transform: translate(15px, -15px);
        }


        &-buttonContainer {
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            justify-content: center;
            flex: 1 0 0%;
            font-size: rem(16px);
        }
        
        

    }
}
