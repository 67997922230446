// album dans la boucle de la liste ou widget
#galerie{
    h1{text-align: center;}
    .galerie {
        &-album {
        margin-bottom: 2em;
        position: relative;
            .inner {
                display:block;
            }
        }

        &-title {
        background: rgba(255, 255, 255, 0.8);
        color: #474747;
        text-transform: uppercase;
        font-size: 1.2rem;
        z-index: 100;
        padding: 5px;
        position: absolute;
        bottom: 1%;
        width: 86%;
        left: 7%;
        }

        &-borderGal {
            border: 1px solid #fff;
            height: 94%;
            width: 90%;
            top: 3%;
            left: 5%;
            position: absolute;
            
           @media(max-width:1199px){height: 90%; width:84%;top: 5%;left: 8%}
           @media(max-width:991px){height: 90%; width: 80%;top: 5%;left: 10%}
           @media(max-width:575px){height: 94%; width: 90%;top: 3%;left: 5%}
            
            }
    }
}